// Import the functions you need from the SDKs you need
import { initializeApp, getApps } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
// import { getFirestore } from "firebase/firestore";
import Cookies from "js-cookie";
import { postMethod } from "../utils/apiMethod";

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG)

// Initialize Firebase
const app = !getApps().length ? initializeApp(firebaseConfig) : getApps()[0];
// export const database = getFirestore();

export const generateToken = async () => {
    try {
      if (typeof window !== 'undefined' && 'Notification' in window) {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          console.log("permission granted")
          const messaging = getMessaging(app);
          if(Cookies.get("fcm") == undefined || Cookies.get("fcm") == 'undefined'){
            const token = await getToken(messaging, {
              vapidKey: process.env.REACT_APP_VAPID_KEY ,
            });
            let onBoardingToken = Cookies.get("auth");
            console.log(onBoardingToken,token)
            if (token &&  onBoardingToken) {
              //Subscribe to FCM messages
              var raw = JSON.stringify({
                fcmToken: token
              })
              const response = await postMethod("notification/subscribeUser",raw);
              if(response?.status){
                Cookies.set("fcm",token)
                return token;
              }
            } else {
              console.log("Failed to retrieve FCM token");
            }
          }else{
            return Cookies.get("fcm")
          }
        } else {
          console.log("Notification permission denied by user");
        }
      } else {
        console.log("Notifications not supported on this platform");
      }
    } catch (error) {
      console.error("Error retrieving FCM token:", error);
      throw error;
    }
};