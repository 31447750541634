import { getToken } from "../auth/cookies";
import { toastError } from "./Toasters";
const url = process.env.REACT_APP_BASE_URL;
export function bearerToken() {
  const token = getToken();
  return token;
}

async function responseChecker(response) {
  if (response.ok) {
    const res = await response.json();
    if (Array.isArray(res.data)) {
      return { status: true, data: [...res.data] };
    } else if (typeof res.data === "object") {
      return { status: true, data: res.data };
    } else {
      console.log("response.data is neither an array nor an object");
    }
  } else if (response.status == 401) {
    return { status: false, code: 401, message: "Session Expired." };
  } else if (response.status == 400) {
    const res = await response.json();
    return { status: false, code: 400, message: res };
  } else {
    //  Will do it later
    return {
      status: false,
      code: response.status,
      message: "Something went wrong.",
    };
  }
}

export const LoginQuery = async () => {
  const token = bearerToken();
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(url + "auth/loginUser", requestOptions);
    return response;
  } catch (e) {
    toastError(e);
    return e
  }
};
