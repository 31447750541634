import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const AnalyticsTracker = () => {
  const location = useLocation();

  useEffect(() => {
    // Capture the start time when the user lands on the page
    const startTime = new Date().getTime();

    // Send pageview event
    ReactGA.send({ hitType: 'pageview', page: location.pathname });

    // Function to calculate and send engagement time when the user navigates away
    return () => {
      const endTime = new Date().getTime();
      const timeSpent = endTime - startTime; // Time in milliseconds

      // Send engagement time event to GA4
      ReactGA.event({
        category: 'Engagement',
        action: 'Time on Page',
        label: location.pathname,
        value: timeSpent // You can send time spent in milliseconds
      });
    };
  }, [location]);

  return null;
};

export default AnalyticsTracker;
