import React, { useContext, useEffect, useState } from "react";
import { Router, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  setUserCookie,
  setOnBoardCookie,
  removeOnBoardCookie,
} from "../auth/cookies";
import { StateContext } from "./Context/StateContext";
import { toastError, toastInfo, toastSuccess } from "../utils/Toasters";
import { setToken } from "../auth/cookies";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
function LinkedInAuth() {
  const navigate = useNavigate();

  const query = useQuery();

  const { setLoading } = useContext(StateContext);

  const url = process.env.REACT_APP_BASE_URL;

  const loginHandler = async (token) => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(url + "auth/loginUser", requestOptions)
      .then((response) => response.json())
      .then(async (res) => {
        if (res.status) {
          setToken(token);
          setOnBoardCookie(token);
          setUserCookie(res);
          toastSuccess("Logged In successfully!");
          navigate("/dashboard");
          setLoading(false);
        } else {
          toastError(res.message);
          setLoading(false);
          // signOut();
        }
      })
      .catch((error) => {
        toastError(error.message);
        setLoading(false);
      });
  };


  async function handleRegisterApiCall(token) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + token);

    var raw = JSON.stringify({
      // name: fullName,
      // email: email, // Pass the necessary registration details
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url + "auth/registerUser", requestOptions)
      .then(async (response) => {
        const result = await response.json();
        if (result?.status) {
          removeOnBoardCookie();
          setOnBoardCookie(token);
          setUserCookie(result);

          const user = result.user;

          if (user) {
            loginHandler(token);
          }

          setLoading(false);
        } else {
          toastError(result.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toastError(error?.message);
      });
  }

  useEffect(() => {
    if (query.get("status") === true || query.get("status") === "true") {
      const linkedInDetails = {
        idToken: query.get("idToken"),
        nextStep: query.get("nextStep"),
      };

      if (query.get("state") === "user" && query.get("nextStep") === "signup") {
        handleRegisterApiCall(linkedInDetails.idToken);
      }

      if (query.get("state") == "user" && query.get("nextStep") == "login") {
        loginHandler(linkedInDetails.idToken);
      }
      // window.close();
    }
  }, []);

  return (
    <div>
      Loading ...
      <ToastContainer />
    </div>
  );
}

export default LinkedInAuth;
