import React, { useEffect } from 'react'

import { getToken } from './auth/cookies'
import { useNavigate } from 'react-router-dom';

function ProtectedRoutes({component}) {

    const token = getToken();
    const navigate = useNavigate();

    useEffect(() => {
        if(!token || token == undefined) {
          navigate("/");
        }
      }, [])

  return (
    <div>
      {component}
    </div>
  )
}

export default ProtectedRoutes
