// import dependencies
import { useContext, useEffect, useState } from "react";
//import files
import ConfirmationModal from "../../Modals/ConfirmationModal";
import ScoreModal from "../../Modals/ScoreModal";
import { getMethod, postMethod } from "../../../../utils/apiMethod";
import { toastError, toastSuccess } from "../../../../utils/Toasters";
import { Link, useLocation } from "react-router-dom";
import { StateContext } from "../../../Context/StateContext";
import { BookMeeting, FollowUser, Score } from "../../icons/Icons";
import ReactPlayer from "react-player";
import CategorySlider from "./CategorySlider";
import ModalWrapper from "../../Modals/ModalWrapper";
import VideoModal from "../../Modals/VideoModal";
import Cookies from "js-cookie";

function Company({
  video,
  name,
  image,
  rating,
  employeeCount,
  description,
  id,
  followed,
  score,
  index,
  classes,
  setRefresh,
  meetingLink,
  categories,
}) {
  // state variables and functions

  const { setFollowStatusChanged } = useContext(StateContext);

  const [status, setStatus] = useState(followed ? "Unfollow" : "Follow");
  const [scored, setScored] = useState(score);
  const [showModal, setShowModal] = useState(false);
  const [showScoreModal, setShowScoreModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const openModalHandler = () => setOpenModal((prev) => !prev);
  const location = useLocation();
  // const [followStatusChanged, setFollowStatusChanged] = useState(false)

  const handleFollwUnfollowApi = () => {
    if (status === "Follow") {
      // setLoading(true);
     let fcmToken = Cookies.get("fcm");
     var raw = JSON.stringify({
       fcmToken: fcmToken || null,
     });
     postMethod(`users/followUnfollowCompany/${id}`,raw)
       .then((res) => {
         if (res?.status) {
           // If currently in "follow" state, change to "following" for 1 second
           setStatus("Following");
           setTimeout(() => {
             setStatus("Unfollow");
           }, 1000);
           toastSuccess(res.message);

           setFollowStatusChanged(true);
         }
       })
       .catch((error) => {
         // setLoading(false);
         toastError(error.message);
         console.log("error.message: ", error.message);
       });
    } else if (status === "Unfollow") {
      // If currently in "unfollow" state, show modal to confirm
      setShowModal(true);
      document.body.style.overflowY = "hidden";
    }
  };

  const handleModalYes = () => {
    // setLoading(true);
    let fcmToken = Cookies.get("fcm");
    var raw = JSON.stringify({
      fcmToken: fcmToken || null,
    });
    postMethod(`users/followUnfollowCompany/${id ? id : 14}`,raw)
      .then((res) => {
        if (res?.status) {
          setStatus("Follow");
          setShowModal(false);
          document.body.style.overflowY = "unset";
          toastSuccess(res.message);

          setFollowStatusChanged(true);
        }
      })
      .catch((error) => {
        // setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  };

  const handleModalNo = () => {
    setShowModal(false);
    document.body.style.overflowY = "unset";
  };

  const handleScoreCancel = () => {
    setShowScoreModal(false);
    document.body.style.overflowY = "unset";
  };
  const handleScoreRateCompany = () => {
    setShowScoreModal(false);
    document.body.style.overflowY = "unset";
  };

  return (
    <div
      style={{ height: "100%" }}
      className={`col-12 d-flex d-flex-column rounded-8 box-shadow1 ${classes}`}
    >
      {/* Upper row */}
      <Link
        state={{ from: location.pathname + location.search }}
        to={`/companyProfile/${id}`}
      >
        <div className="d-flex gap-2 font-12 p-3 m-1 border-bottom-dark">
          <div
            className="col-4 d-flex d-justify-center p-relative h-full"
            style={{ height: "129px" }}
          >
            {video ? (
              <ReactPlayer url={video} width="100%" height={129} />
            ) : (
              <img
                src="/assets/videoThumbnail.png"
                alt="company video"
                className="rounded-8 h-full w-full image-cover"
                // style={{ width: "150.023px", height: "129px" }}
              />
            )}

            {video && (
              <div
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setOpenModal(true);
                }}
                className="gradient-overlay rounded-12 p-relative d-flex d-align-center d-justify-center"
              >
                {/* <img
                src="/assets/PlayButton.svg"
                className="p-absolute col-6 m-auto left-0 right-0 bottom-0 top-0"
              /> */}

                <div className="bg-image-play-btn"></div>
              </div>
            )}
          </div>
          <div className="col-8 d-flex d-flex-column gap-1">
            <div className="d-flex d-align-center gap-1">
              <div style={{ height: "57px" }}>
                <img
                  style={{ objectFit: "contain", width: "auto" }}
                  height="100%"
                  src={`${image ? image : "/assets/Image-Not-Found.png"}`}
                  alt={name}
                />
              </div>
              {/* <div className="d-flex col-2 d-justify-center d-align-center">
                <div className="bg-grey-1 d-flex d-justify-center rounded-20 col-12 font-14">
                  {rating}
                </div>
              </div> */}

              <span className="h4 f-700 text-blue text-ellipsis-1">{name}</span>
            </div>
            <div className="col-12 mt-1">
              <CategorySlider categories={categories} />
            </div>
            <div className="text-black font-12 f-400 text-left">
              <p className={`m-0 word-break text-grey-4 text-ellipsis-2`}>
                {description}
              </p>
            </div>
          </div>
        </div>
      </Link>

      {/* lower row */}

      <div className="d-flex">
        {/* Employee count */}
        {/* <div className="d-flex d-flex-column d-justify-center col-4 p-5">
          <div className="font-16">
            <b>
              {employeeCount}
              {employeeCount > 0 ? "+" : ""}
            </b>
          </div>
          <div className="font-16">Employees</div>
        </div> */}
        {/* Buttons */}
        <div className="col-12 p-5">
          <div className="row d-flex-column gap-1">
            <div className="d-flex gap-1 d-justify-center">
              <div className="col-6">
                <button
                  onClick={handleFollwUnfollowApi}
                  className={`btn col-12 d-flex d-align-center gap-1 ${
                    status === "Unfollow" ? "btn-white-thin" : "btn-aqua-thin"
                  }`}
                >
                  {status === "Follow" && <FollowUser />}

                  {status}
                </button>
              </div>

              <div className="col-6">
                <button
                  //   className={`btn btn-aqua-thin col-12 d-flex d-align-center gap-1 btn-disable-score
                  //     ${scored ? "cursor-disabled" : ""}
                  // `}
                  className={`btn btn-aqua-thin col-12 d-flex d-align-center gap-1 btn-disable-score`}
                  // onClick={() => (scored ? "" : setShowScoreModal(true))}
                >
                  <Score />
                  {scored ? "Scored" : "Score"}
                </button>
              </div>
            </div>
            <div>
              <button
                onClick={() => {
                  meetingLink
                    ? window.open(meetingLink, "_blank")
                    : toastError("No meeting link found for this company!");
                }}
                className="btn btn-sea-green-thin col-12 d-flex d-align-center gap-1"
              >
                <BookMeeting />
                Book Meeting
              </button>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <div>
          <ConfirmationModal onYes={handleModalYes} onNo={handleModalNo} />
        </div>
      )}
      {showScoreModal && (
        <div>
          <ScoreModal
            handleCancel={handleScoreCancel}
            handleRateCompany={handleScoreRateCompany}
            companyId={id}
            setRefresh={setRefresh}
          />
        </div>
      )}
      {openModal && (
        <ModalWrapper>
          <VideoModal
            url={video}
            closeHandler={() => setOpenModal(false)}
          ></VideoModal>
        </ModalWrapper>
      )}
    </div>
  );
}

export default Company;
