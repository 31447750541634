import React, { useContext } from "react";
import useFirebaseAuth from "../../auth/useFirebaseAuth";
import { LoginQuery } from "../../utils/auth";
import {
  removeOnBoardCookie,
  setUserCookie,
  setOnBoardCookie,
} from "../../auth/cookies";
import { useNavigate } from "react-router-dom";
import { toastError } from "../../utils/Toasters";
import { StateContext } from "../Context/StateContext";

function SignInWithGoogle() {
  const url = process.env.REACT_APP_BASE_URL;
  
  const navigate = useNavigate();
  const { signInWithGoogle, setRole } = useFirebaseAuth();

  const { loading, setFirstLoading } = useContext(StateContext);

  function handleRegisterApiCall(userCredential, details) {
    console.log(userCredential?.user.displayName);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + userCredential.user.multiFactor.user.accessToken
    );

    var raw = JSON.stringify({
      name: details.name,
      ownerName: details.ownerName,
      phone: details.phone,

      //   email: credentials.email ,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url + "auth/registerUser", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          removeOnBoardCookie();
          setOnBoardCookie(userCredential.user.multiFactor.user.accessToken);
          setUserCookie(result);

          console.log("Registration successful:", userCredential.user);
          navigate("/dashboard");
        } else {
          console.log("Error: ", result);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  const signInWithGoogleHandler = async () => {
    signInWithGoogle()
      .then((authUser) => {
        console.log("AUTH USER: ", authUser);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
          "Authorization",
          `Bearer ${authUser.user.multiFactor.user.accessToken}`
        );

        var raw = JSON.stringify({
          email: authUser.user.email,
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
          body: raw,
        };

        try {
          fetch(url + "auth/checkUserExistence", requestOptions)
            .then((response) => {
              return response.json();
            })
            .then((response) => {
              console.log("Response from existence api: ", response);

              if (response.status) {
                if (response.exists) {
                  LoginQuery()
                    .then((res) => {
                      return res.json();
                    })
                    .then((result) => {
                      if (result?.status) {
                        setUserCookie(result);
                        setFirstLoading(false);
                        navigate("/dashboard");
                      } else {
                        setFirstLoading(false);
                        toastError(result.message);
                      }
                    });
                } else {
                  let details = {
                    email: authUser.user.email,
                    ownerName: authUser.user.displayName,
                    name: "-",
                    phone: authUser.user.phoneNumber
                      ? authUser.user.phoneNumber
                      : "-",
                  };

                  console.log("DETAILS: ", details);

                  handleRegisterApiCall(authUser, details);
                }
              } else {
                console.log("RESPONSE.STATUS: ", response.status);
              }
            });
        } catch (error) {
          setFirstLoading(false);
          console.log("Error: ", error);
          toastError(error.message);
        }
      })
      .catch((error) => {
        // setErrorBox(true)
        // setErrorMsg(error.message);
        setFirstLoading(false);
        console.log("Error: ", error);
        if (error.message == "Firebase: Error (auth/user-not-found).") {
          toastError("User Not Found", {
            toastId: "1",
          });
        } else if (error.message == "Firebase: Error (auth/wrong-password).") {
          toastError("Password Invalid", {
            toastId: "1",
          });
        } else if (
          error.message ==
          "Firebase: There is no user record corresponding to this identifier. The user may have been deleted. (auth/user-not-found)."
        ) {
          toastError("Invalid Email", {
            toastId: "1",
          });
        } else if (
          error.message ==
          "Firebase: The password is invalid or the user does not have a password. (auth/wrong-password)."
        ) {
          toastError("Incorrect Password", {
            toastId: "1",
          });
        } else {
          var code = error.code.substring(error.code.lastIndexOf("/") + 1);
          var newcode = code.replaceAll("-", " ");
          toastError(newcode, {
            toastId: "2",
          });
        }
      });
  };

  return (
    <div
      className="input-field p-2 font-20 text-light-grey2 text-center d-flex d-justify-center gap-2 hover-effect"
      onClick={signInWithGoogleHandler}
    >
      <img src="/assets/GoogleLogo.svg" />
      Sign in with Google
    </div>
  );
}

export default SignInWithGoogle;
