import React, { useContext, useEffect, useRef, useState } from "react";
import UserSettingsModal from "./general/Modals/UserSettingsModal";
import LoggedInHeader from "./general/LoggedInHeader";
import { getMethod, patchFormMethod, postFormMethod } from "../utils/apiMethod";
import { toastError, toastSuccess } from "../utils/Toasters";
import Footer from "./general/Footer";
import useFirebaseAuth from "../auth/useFirebaseAuth";
import cookies from "js-cookie";
import { setOnBoardCookie, getToken } from "../auth/cookies";
import { StateContext } from "./Context/StateContext";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import styles from "../styles/modular css/UserSettings.module.css"

function UserSettings() {
  const { setLoading, setShowLogoutModal } = useContext(StateContext);
  const navigate = useNavigate();

  const [isActive, setIsActive] = useState(0);
  const [userDetails, setUserDetails] = useState({});
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [bio, setBio] = useState(null);
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [profilePicUrl, setProfilePicUrl] = useState(null);

  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [isOldPassCorrect, setIsOldPassCorrect] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");
  const { changePassword, signInWithEmailAndPassword } = useFirebaseAuth();

  // token variable
  const token = getToken();

  // USER Image Upload variables and functions
  const [profilePic, setProfilePic] = useState(null);
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click(); // Trigger the file input dialog
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setProfilePic(selectedFile);
  };
  // COMPANY Image Upload variables and functions
  const [companyPic, setCompanyPic] = useState(null);
  const fileCompanyInputRef = useRef(null);

  const handleCompanyClick = () => {
    fileCompanyInputRef.current.click(); // Trigger the file input dialog
  };

  const handleCompanyFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setCompanyPic(selectedFile);
  };

  const checkOldPassword = async () => {
    try {
      const response = await signInWithEmailAndPassword(email, oldPassword);
      console.log("RES PWD: ", response);
      if (response.user) {
        const newToken = response.user.multiFactor.user.accessToken;
        setOnBoardCookie(newToken);

        setIsOldPassCorrect(true);
        if (
          confirmPassword?.trim()?.length > 0 &&
          newPassword?.trim()?.length > 0
        ){
          if (confirmPassword === newPassword) {
            changePassword(newPassword, updateDetailsApiCall);
          } else {
            toastError("New password and Confirm password do not match!");
          }
        }else{
          toastError("Please enter valid new and confirm password!")
        }
      }
    } catch (error) {
      setIsOldPassCorrect(false);
      if (error.message == "Firebase: Error (auth/user-not-found).") {
        toastError("User Not Found", {
          toastId: "1",
        });
      } else if (error.message == "Firebase: Error (auth/wrong-password).") {
        toastError("Password Invalid", {
          toastId: "1",
        });
      } else if (
        error.message ==
        "Firebase: There is no user record corresponding to this identifier. The user may have been deleted. (auth/user-not-found)."
      ) {
        toastError("Invalid Email", {
          toastId: "1",
        });
      } else if (
        error.message ==
        "Firebase: The password is invalid or the user does not have a password. (auth/wrong-password)."
      ) {
        toastError("Incorrect Password", {
          toastId: "1",
        });
      } else {
        var code = error.code.substring(error.code.lastIndexOf("/") + 1);
        var newcode = code.replaceAll("-", " ");
        var capitalizedCode =
          newcode.charAt(0).toUpperCase() + newcode.slice(1);

        toastError(capitalizedCode, {
          toastId: "2",
        });
      }
    }
  };

  const updateDetailsApiCall = async (token) => {
    setLoading(true);
    var formdata = new FormData();

    if (userDetails.name !== name) {
      formdata.append("name", name);
    }
    // if (userDetails.bio !== bio) {
    //   formdata.append("bio", bio);
    // }
    if (userDetails?.profilePicUrl !== profilePic) {
      formdata.append("profilePic", profilePic);
    }
    if (userDetails.phone !== phone) {
      formdata.append("phone", phone);
    }
    if (userDetails.companyName !== companyName) {
      formdata.append("companyName", companyName);
    }
    if (userDetails.companyLogoUrl !== companyPic) {
      formdata.append("companyLogo", companyPic);
    }

    // patchFormMethod(`users/updateDetails`, formdata)
    // .then((res) => {
    //    // setLoading(false);
    //   if (res.status == true) {

    //       toastSuccess("Details updated successfully!");
    //          console.log("res: ", res);

    //   }
    // })
    // .catch((error) => {
    //   // setLoading(false);
    //   toastError(error.message);
    //   console.log("error.message: ", error.message);
    // });

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    try {
      let baseUrl = process.env.REACT_APP_BASE_URL
      const response = await fetch(
        `${baseUrl}users/updateDetails`,
        requestOptions
      );;
      const data = await response.json();
      if (data.status == true) {
        setLoading(false);
        toastSuccess("Details updated successfully!");
        navigate("/dashboard");

        return data;
      } else {
        setLoading(false);
        toastError(data.message);
        return response;
      }
    } catch (e) {
      toastError(e.message);
      return e.message;
    }
  };

  const submitHandler = (e) => {
    // setLoading(true);

    // checking of the values changed in field

    if (oldPassword?.length > 0) {
      checkOldPassword();
    } else {
      updateDetailsApiCall(token);
    }
  };

  useEffect(() => {
    setLoading(true);
    getMethod(`users/getMyUserDetails`)
      .then((res) => {
        setLoading(false);
        if (res?.status) {
          //    setUserDetails(res?.user);
          setName(res?.user?.name);
          setEmail(res?.user?.email);
          setProfilePicUrl(res?.user?.profilePicUrl);
          setProfilePic(res?.user?.profilePicUrl);
          setBio(res?.user?.bio);
          if(phone && phone?.charAt(0)!="+") setPhone(`+${res?.user?.phone}`);
          else setPhone(res?.user?.phone)
          setUserDetails(res?.user);
          setCompanyName(res.user?.companyName);
          setCompanyPic(res.user?.companyLogoUrl);
        }
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  }, []);

  return (
    <div className="d-fllex d-flex-column ">
      <LoggedInHeader />
      <div className="p-10 mt-170" style={{ backgroundColor: "#F6F6F6" }}>
        <div
          className={`${styles.container} container d-flex col-12 col-md-11 col-xxl-10 m-auto mb-10 gap-8`}
        >
          {/* SIDE PANEL */}

          <div className="col-12 col-xxl-3">
            <div className="d-flex d-flex-column rounded-16 bg-white m-auto p-5 gap-1">
              <div className={`${styles.sidePanelUpper} d-flex d-flex-column gap-1`}>
                {/* BACK BUTTON */}
                <Link to="/dashboard">
                    <img
                      width={42}
                      height={42}
                      src="/assets/arrow-back-circle.svg"
                    />
                </Link>

                {/* USER DETAILS */}
                <div className="d-flex gap-2 d-align-center">
                  {/* image */}
                  <div
                    className="col-3"
                    style={{ height: "58px", width: "56px" }}
                  >
                    <img
                      src={`${
                        profilePicUrl
                          ? profilePicUrl
                          : "/assets/Image-Not-Found.png"
                      }`}
                      className="rounded-50"
                      style={{ height: "58px", width: "56px" }}
                    />
                  </div>

                  {/* Name and email */}
                  <div className="d-flex-column">
                    <div className="font-24 f-700 text-capitalize">
                      {name?.split(" ")[0]}
                    </div>

                    <div className="text-grey-19 font-12 word-break">
                      {email}
                    </div>
                  </div>
                </div>
              </div>

              {/* ACTION BUTTONS */}

              <div className={`${styles.actionButtons} d-flex d-flex-column gap-2`}>
                <div
                  role="button"
                  className={`d-flex d-align-center gap-1 p-3 cursor-pointer
                                        ${
                                          isActive === 0
                                            ? "bg-sea-green rounded-10 text-white"
                                            : ""
                                        }`}
                  onClick={() => setIsActive(0)}
                >
                  <img
                    src={`${
                      isActive !== 0
                        ? "/assets/Settings-black.svg"
                        : "/assets/Settings-white.svg"
                    }`}
                  />
                  <div className="h5 f-400 l-20">Settings</div>
                </div>

                <div
                  role="button"
                  className={`d-flex d-align-center gap-1 p-3 cursor-pointer
                                        ${
                                          isActive === 1
                                            ? "bg-sea-green rounded-10 text-white"
                                            : ""
                                        }`}
                  onClick={() => {
                    setShowLogoutModal(true);
                  }}
                >
                  <img src="/assets/Logout.svg" />
                  <div className="h5 f-400 l-20">Logout</div>
                </div>
              </div>
            </div>
          </div>

          {/* SETTINGS BODY */}

          {isActive === 0 ? (
            <div className="d-flex d-flex-column col-12 col-xxl-10 bg-white rounded-8 p-5 gap-5">
              {/* Header text */}

              <div className="d-flex d-flex-column">
                {/* Header text */}

                <h1 className="f-600 text-black-2">Settings</h1>

                <div className="h6 text-grey-19">Edit your profile details</div>
              </div>

              {/* Basic Details */}
              <div className="d-flex d-flex-column gap-3">
                <div className="ls-2 text-black-2 f-600 font-14">
                  BASIC DETAILS
                </div>

                {/* Input fields */}

                {/* PROFILE PIC */}

                <div className="d-flex d-flex-column gap-1">
                  <div
                    className="ls-2 text-black-2 font-14 f-600"
                    style={{ color: "#252322" }}
                  >
                    Profile Pic
                  </div>

                  <div
                    className="d-flex d-align-center rounded-10 border-grey-3 border"
                    style={{ height: "100px", width: "100px" }}
                  >
                    <div className="d-flex d-align-center m-auto h-full w-full rounded-10 p-2">
                      {profilePic ? (
                        <img
                          src={
                            profilePic instanceof File
                              ? URL.createObjectURL(profilePic)
                              : profilePic
                          } // Create URL for the selected file
                          alt="Uploaded File"
                          className="d-flex d-align-center m-auto h-full w-full rounded-50 cursor-pointer"
                          onClick={handleClick}
                        />
                      ) : (
                        <img
                          src="assets/Image-Not-Found.png"
                          alt="upload"
                          className="m-auto cursor-pointer w-full h-full rounded-50"
                          onClick={handleClick}
                        />
                      )}

                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }} // Hide the file input
                        onChange={handleFileChange}
                        accept="image/*" // Accept only image files
                      />
                    </div>
                  </div>
                </div>
                {/* NAME */}

                <div className="d-flex d-flex-column gap-1">
                  <div
                    className="ls-2 text-black-2 font-14 f-600"
                    style={{ color: "#252322" }}
                  >
                    Name
                  </div>

                  <div className="col-12 border-grey-2 p-2 rounded-8">
                    <input
                      className="col-12 border-none settings-input-field"
                      value={name}
                      onChange={(e) => {
                        const regex = /^[a-zA-Z\s]*$/;

                        if (regex.test(e.target.value)) {
                          setName(e.target.value);
                        }
                      }}
                      type="text"
                    />
                  </div>
                </div>

                {/* PHONE */}

                <div className="d-flex d-flex-column gap-1">
                  <div
                    className="ls-2 text-black-2 font-14 f-600"
                    style={{ color: "#252322" }}
                  >
                    Phone
                  </div>
                  <PhoneInput
                    country={"us"}
                    value={phone}
                    onChange={(newPhone) => {
                      newPhone && setPhone("+" + newPhone);
                    }}
                    inputProps={{
                      autoFocus: true,
                      required: true,
                    }}
                    containerStyle={{
                      width: "100%",
                    }}
                    inputStyle={{
                      width: "100%",
                      paddingTop: "7px",
                      paddingBottom: "7px",
                    }}
                  />
                </div>

                {/* BIO */}

                {/* <div className="d-flex d-flex-column gap-1">
                  <div
                    className="ls-2 text-black-2 font-14 f-600"
                    style={{ color: "#252322" }}
                  >
                    Bio
                  </div>

                  <div className="col-12 border-grey-2 p-2 rounded-8">
                    <input
                      className="col-12 border-none settings-input-field w-full h-full bg-none rounded-8"
                      value={bio ? bio : ""}
                      type="text"
                      onChange={(e) => setBio(e.target.value)}
                    />
                  </div>
                </div> */}

                {/* EMAIL */}
                <div className="d-flex d-flex-column gap-1">
                  <div
                    className="ls-2 text-black-2 font-14 f-600"
                    style={{ color: "#252322" }}
                  >
                    Email
                  </div>

                  <div className="col-12 border-grey-2 rounded-8">
                    <input
                      className="col-12 border-none settings-input-field h-full p-3 text-grey-17 rounded-8"
                      type="email"
                      value={email}
                      disabled
                    />
                  </div>
                </div>

                {/* COMPANY DETAILS */}

                <div className="ls-2 text-black-2 f-600 font-14">
                  COMPANY DETAILS
                </div>

                {/* Company Logo */}

                <div className="d-flex d-flex-column gap-1">
                  <div
                    className="ls-2 text-black-2 font-14 f-600"
                    style={{ color: "#252322" }}
                  >
                    Company Logo
                  </div>

                  <div
                    className="d-flex d-align-center rounded-10 border-grey-3 border"
                    style={{ height: "100px", width: "100px" }}
                  >
                    <div className="d-flex d-align-center m-auto h-full w-full rounded-10 p-2">
                      {companyPic ? (
                        <img
                          src={
                            companyPic instanceof File
                              ? URL.createObjectURL(companyPic)
                              : companyPic
                          } // Create URL for the selected file
                          alt="Uploaded File"
                          className="d-flex d-align-center m-auto h-full w-full rounded-50 cursor-pointer"
                          onClick={handleCompanyClick}
                        />
                      ) : (
                        <img
                          src="assets/Image-Not-Found.png"
                          alt="upload"
                          className="m-auto cursor-pointer w-full h-full rounded-50"
                          onClick={handleCompanyClick}
                        />
                      )}

                      <input
                        type="file"
                        ref={fileCompanyInputRef}
                        style={{ display: "none" }} // Hide the file input
                        onChange={handleCompanyFileChange}
                        accept="image/*" // Accept only image files
                      />
                    </div>
                  </div>
                </div>

                {/* Company Name */}

                <div className="d-flex d-flex-column gap-1">
                  <div
                    className="ls-2 text-black-2 font-14 f-600"
                    style={{ color: "#252322" }}
                  >
                    Comany Name
                  </div>

                  <div className="col-12 border-grey-2 p-2 rounded-8">
                    <input
                      className="col-12 border-none settings-input-field"
                      value={companyName ? companyName : ""}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </div>
                </div>

                {/* CHANGE PASSWORD */}
                <div className="ls-2 text-black-2 f-600 font-14">
                  CHANGE PASSWORD
                </div>
                <div className="d-flex d-flex-column gap-1">
                  <div
                    className="ls-2 text-black-2 font-14 f-600"
                    style={{ color: "#252322" }}
                  >
                    Old password
                  </div>

                  <div className="col-12 border-grey-2 p-2 rounded-8">
                    <input
                      className="col-12 border-none settings-input-field"
                      type="text"
                      value={oldPassword}
                      onChange={(e) => {
                        setOldPassword(e.target.value);
                        setIsOldPassCorrect(true);
                      }}
                    />
                  </div>
                  {isOldPassCorrect === "" || isOldPassCorrect ? (
                    ""
                  ) : (
                    <p className="text-red m-0">Password is incorrect.</p>
                  )}
                </div>

                <div className="d-flex d-flex-column gap-1">
                  <div
                    className="ls-2 text-black-2 font-14 f-600"
                    style={{ color: "#252322" }}
                  >
                    New password
                  </div>

                  <div
                    className={`col-12 border-grey-2 p-2 rounded-8 ${
                      oldPassword.trim().length > 0
                        ? ""
                        : "bg-grey-2 cursor-disabled"
                    }`}
                  >
                    <input
                      className={`col-12 border-none settings-input-field bg-none ${
                        oldPassword.trim().length > 0
                          ? ""
                          : "bg-grey-2 cursor-disabled"
                      }`}
                      type="text"
                      value={newPassword}
                      readOnly={!(oldPassword.trim().length > 0)}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="d-flex d-flex-column gap-1">
                  <div
                    className="ls-2 text-black-2 font-14 f-600"
                    style={{ color: "#252322" }}
                  >
                    Confirm new password
                  </div>

                  <div
                    className={`col-12 border-grey-2 p-2 rounded-8 ${
                      oldPassword.trim().length > 0
                        ? ""
                        : "bg-grey-2 cursor-disabled"
                    }`}
                  >
                    <input
                      className={`col-12 border-none settings-input-field bg-none ${
                        oldPassword.trim().length > 0
                          ? ""
                          : "bg-grey-2 cursor-disabled"
                      }`}
                      value={confirmPassword}
                      readOnly={!(newPassword.trim().length > 0)}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                </div>

                <div className="d-flex d-flex-row-reverse">
                  {/* <button className='bg-sea-green font-14 text-white pl-5 pr-5 p-3 border-none rounded-8' onClick={() => changePassword(newPassword)}>Save</button> */}
                  <button
                    className="btn btn-hover btn-sea-green rounded-8"
                    onClick={submitHandler}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default UserSettings;
