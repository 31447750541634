import React, { useContext, useEffect, useState } from 'react'
import UserSettingsModal from './Modals/UserSettingsModal'
import { getUserCookie } from '../../auth/cookies'
import { StateContext } from '../Context/StateContext'
import LogoutConfirmationModal from './Modals/LogoutConfirmationModal'
import { getMethod } from '../../utils/apiMethod'
import { toastError } from '../../utils/Toasters'
import styles from "../../styles/modular css/LoggedInHeader.module.css"
import Listener from './Listener'
import NotificationModal from './Modals/NotificationModal'
import { ToastContainer } from 'react-toastify'

function LoggedInHeader() {
    const {showLogoutModal, setShowLogoutModal} = useContext(StateContext)

    const [showModal, setShowModal] = useState(false)
    const userName = getUserCookie('userName');
    // const email = getUserCookie('email');
    const profilePicUrl = getUserCookie('profilePicUrl');

    const [name, setName] = useState(null);
  const [image, setImage] = useState(null);
  
    useEffect(() => {
        // setLoading(true);
        getMethod(`users/getMyUserDetails`)
          .then((res) => {
            if (res?.status) {
              //    setUserDetails(res?.user);
              setName(res?.user?.name);
              setImage(res?.user?.profilePicUrl);
           
            }
          })
          .catch((error) => {
            // setLoading(false);
            toastError(error.message);
            console.log("error.message: ", error.message);
          });
      }, []);
    
  return (
    <div
      className={`${styles.header} d-flex d-flex-column bg-white p-fixed left-0 top-0 min-w-full pb-2`}
    >
      {/* Colored ribbon */}
      <div className="row">
        <div className="col-3 bg-blue" style={{ height: "12px" }}>
          {" "}
        </div>
        <div className="col-3 bg-aqua" style={{ height: "12px" }}>
          {" "}
        </div>
        <div className="col-3 bg-sea-green" style={{ height: "12px" }}>
          {" "}
        </div>
        <div className="col-3 bg-yellow2" style={{ height: "12px" }}>
          {" "}
        </div>
      </div>

      {/* Navbar */}
      <div className="container row d-justify-space-between d-align-center col-11">
        {/* Brand logo */}
        <div className="col-3 col-sm-2">
          <img src="/assets/FutrLogo.png" className="col-12" />
        </div>

        {/* Navigation buttons */}
        <div
          className={`${styles.navBtns} d-flex gap-8 d-align-center d-justify-space-between`}
        >
          <a href="/search?topCompanies=true">
            <div className="nav-text">Showcase</div>
          </a>

          <div className="nav-text">About futr connect</div>

          <div className="d-flex gap-1">
            <NotificationModal/>
            <div
              role="button"
              className={`${styles.dropdownBtn} bg-sea-green border-none rounded-10 p-relative
                    font-20 d-flex gap-3 d-align-center p-3 cursor-pointer`}
              onClick={() => {
                setShowModal(!showModal);
              }}
            >
              <div className="d-flex d-align-center">
                <img
                  src={`${image ? image : "/assets/Image-Not-Found.png"}`}
                  className="rounded-50 border-thick-white"
                  style={{ height: "50px", width: "50px" }}
                />
              </div>

              <div className="font-20 text-white text-capitalize">
                {name ? name?.split(" ")[0] : "User"}
              </div>

              <div>
                <img src="/assets/arrow-down-gw.svg" />
              </div>
            </div>
            {showModal ? (
              <div
                className={`${styles.popUp} p-absolute z-1000 col-6 col-sm-4 col-md-3 col-lg-2`}
              >
                <UserSettingsModal setIsModalActive={setShowModal} />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <Listener />

      {showLogoutModal && <LogoutConfirmationModal />}

      <ToastContainer/>
    </div>
  );
}

export default LoggedInHeader
