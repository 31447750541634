import { getToken } from "../../auth/cookies";
import styles from "../../styles/modular css/Login.module.css";
function Footer({btnLink}) {
  return (
    <div className={`bg-blue ${styles.footer}`}>
      <div
        className={`${styles.footerWrapper} row container col-11 d-flex d-align-center d-justify-space-between gap-3`}
        style={{ height: "437px" }}
      >
        {/* Brand Logo */}
        <div className="d-flex d-flex-column gap-2">
          <img
            className={`${styles.logo}`}
            src="/assets/FooterLogo.svg"
            alt="brandLogo"
            width={292}
            height={118}
          />
          {getToken() ? (
            ""
          ) : (
          <a href={btnLink}>
            <button
              className={`${styles.signInbutton} btn btn-sea-green btn-rounded font-20`}
            >
              Sign In or Join
            </button>
          </a>
          )} 
          <p className="font-20 text-white ">© 2023 All Rights Reserved Futr</p>
        </div>

        {/* LinkedIn logo */}
        <div className="d-flex d-flex-column d-align-end gap-2">
          <img
            src="/assets/linkedInLogo.svg"
            alt="linkedIn"
            width={50}
            height={50}
          />
          <div
            className={`${styles.navigationBtn} d-flex gap-6 d-align-center`}
          >
            <div className="font-24 text-yellow2">Contact</div>
            {/* <div className="font-24 text-yellow2">Help Center</div> */}
            <div className="font-24 text-yellow2">About</div>
            <a href="https://futr.tv/" target="_blank">
              <div className="font-24 text-yellow2">futr.tv</div>
            </a>
            <a href="https://static.futrconnect.io/futr_connect_privacy_policy.html">
              <div className="font-24 text-yellow2">Privacy Policy</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
